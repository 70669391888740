import { Grid } from '@mui/material';
import React, { useMemo, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { filter, findIndex, groupBy, prop, propEq, reject } from 'ramda';
import { selectPoliciesForEmails } from './Emails.slice';
import MobilePolicyHeader from '../MobileNumber/common/MobilePolicyHeader';
import { PolicyAlternateCodes } from '../../../common/types/PolicyTypes';
import PolicyCard from '../Common/PolicyCard';
import UnassignedEmail from './UnassignedEmail';
import UnassignedEmailPolicies from './UnassignedEmailPolicies';
import './Email.scss';
import { POLICY_LOCK_FLAG } from '../../../common/Enums';

const ManageEmailHeader = ({
	toggleRemoveNumberModal,
	toggleChangeEmailModal,
	handleAssignPolicy,
	handleRemoveForUnAssignedEmails,
	togglePrimaryChangeModal,
}: any) => {
	const [count, setCount] = useState(0);
	const groupPoliciesByNumber = groupBy<any>(prop<any>('email'));
	const { emailBasedPolicies, unAssignedEmails } = useSelector(
		selectPoliciesForEmails
	);
	const { assignedPoliciesObj, unAssignedPolicies }: any = useMemo(() => {
		let data: any = { assignedPoliciesObj: {}, unAssignedPolicies: [] };
		if (emailBasedPolicies?.length > 0) {
			const assignedPoliciesObjLocal = groupPoliciesByNumber(
				emailBasedPolicies || []
			);
			const unAssignedPoliciesLocal = assignedPoliciesObjLocal.null;
			delete assignedPoliciesObjLocal.null;
			data = {
				assignedPoliciesObj: assignedPoliciesObjLocal,
				unAssignedPolicies: unAssignedPoliciesLocal || [],
			};
		}
		return data;
	}, [emailBasedPolicies, groupPoliciesByNumber]);

	const lifePolicies = filter(
		propEq(PolicyAlternateCodes.LIFE, 'alternateCode')
	);
	const healthPolicies: any = filter(
		propEq(PolicyAlternateCodes.HEALTH, 'alternateCode')
	);
	const assetPolicies: any = filter(
		propEq(PolicyAlternateCodes.ASSET, 'alternateCode')
	);

	useEffect(() => {
		setCount(count + 1);
	}, []);

	const unAssignedEmailsLocal = useMemo(() => {
		if (unAssignedEmails && emailBasedPolicies) {
			return reject(
				({ email }: any) =>
					findIndex(propEq(email, 'email'))(emailBasedPolicies) >= 0,
				unAssignedEmails
			);
		}
		return [];
	}, [unAssignedEmails, emailBasedPolicies]);

	return (
		<>
			<Grid container spacing={5} className="mt-n10">
				{Object.keys(assignedPoliciesObj).map(
					(email: string, index: number) => (
						<Grid item key={email} className="pt-0">
							<MobilePolicyHeader
								index={index}
								mobileNo={email}
								verified={assignedPoliciesObj[email].some((item: any) => item.emailVerified === 'Y')}
								primary={assignedPoliciesObj[email][0].primaryFlag}
								onRemove={() =>
									toggleRemoveNumberModal(
										email,
										assignedPoliciesObj[email][0].primaryFlag
									)
								}
								showAssignPolicyButton={false}
								showAssignPolicyRegularList
								handleAssignPolicy={() =>
									handleAssignPolicy({
										email,
										emailVerified: assignedPoliciesObj[email][0].emailVerified,
										primaryFlag: assignedPoliciesObj?.[email]?.[0].primaryFlag,
									})
								}
								isEmail
								onChangePrimary={() => togglePrimaryChangeModal({ email })}
								policy={assignedPoliciesObj[email][0]}
							/>
							<Grid
								item
								className="mobile-Policy-screen policy-list-container p-20"
							>
								{lifePolicies(assignedPoliciesObj[email]).map((policy: any) => (
									<PolicyCard
										policyDetails={policy}
										key={policy?.policyNo}
										changeButtonText="CHANGE_EMAIL"
										onChangeClick={() => toggleChangeEmailModal(policy)}
										disabled={policy?.insIntegFlag?.toUpperCase() !== 'Y' || policy?.emailEndoFlag?.toUpperCase() !== 'Y' || POLICY_LOCK_FLAG.includes(policy?.daFlag)}
									/>
								))}
								{healthPolicies(assignedPoliciesObj[email]).map(
									(policy: any) => (
										<PolicyCard
											policyDetails={policy}
											key={policy?.policyNo}
											changeButtonText="CHANGE_EMAIL"
											onChangeClick={() => toggleChangeEmailModal(policy)}
											disabled={policy?.insIntegFlag?.toUpperCase() !== 'Y' || policy?.emailEndoFlag?.toUpperCase() !== 'Y' || POLICY_LOCK_FLAG.includes(policy?.daFlag)}
										/>
									)
								)}
								{assetPolicies(assignedPoliciesObj[email]).map(
									(policy: any) => (
										<PolicyCard
											policyDetails={policy}
											key={policy?.policyNo}
											changeButtonText="CHANGE_EMAIL"
											onChangeClick={() => toggleChangeEmailModal(policy)}
											disabled={policy?.insIntegFlag?.toUpperCase() !== 'Y' || policy?.emailEndoFlag?.toUpperCase() !== 'Y' || POLICY_LOCK_FLAG.includes(policy?.daFlag)}
										/>
									)
								)}
							</Grid>
						</Grid>
					)
				)}
			</Grid>
			{unAssignedEmailsLocal?.length > 0 && (
				<div className="mt-15">
					<UnassignedEmail
						emails={unAssignedEmailsLocal}
						handleAssignPolicy={handleAssignPolicy}
						handleRemoveForUnAssignedEmails={handleRemoveForUnAssignedEmails}
						togglePrimaryChangeModal={togglePrimaryChangeModal}
					/>
				</div>
			)}

			{unAssignedPolicies?.length > 0 && (
				<div className="mt-15">
					<UnassignedEmailPolicies
						policies={unAssignedPolicies}
						onChangeEmail={toggleChangeEmailModal}
					/>
				</div>
			)}
		</>
	);
};

export default ManageEmailHeader;
