import React, { useEffect } from 'react';
import Typography from '@mui/material/Typography';
import useRazorpay from 'react-razorpay';
import { useTranslation } from 'react-i18next';
import { Grid } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate, Link } from 'react-router-dom';
import CircularProgress from '@mui/material/CircularProgress';
import { ReactComponent as Cancel } from '../../../../common/icons/cancel-icon.svg';
import SvgConverter from '../../../../common/SvgConverter';
import { ReactComponent as RupeeIconBlack } from '../../../../common/icons/dashboard/grey-rupee.svg';

import '../Dashboard.scss';

import LabelWithIcon from '../../../../common/ui/LabelWithIcon';
import CustomButton from '../../../../common/ui/CustomButton';
import Separator from '../../../../common/ui/Separator';
import theme from '../../../../common/theme';
import { ReactComponent as RupeeOrangeIcon } from '../../../../common/icons/dashboard/calendar_orange_ruppee_icon.svg';

import { ReactComponent as PlusBlack } from '../../../../common/icons/dashboard/grey-plus.svg';
import {
	policyRenewalRequest,
	selectPolicyRenewal,
	selectPolicyRenewalLoading,
} from '../../Payment/Payment.slice';
import { convertAmount, getFormatDate } from '../../../../common/utils';

import {
	fetchUserProfile,
	selectUserProfile,
} from '../../Profile/Profile.slice';
import {
	PAYMENT_GATEWAY_TYPES,
	PAYMENT_STATUS,
} from '../../../../common/Enums';
import { APP_ROUTES } from '../../../../common/constants/Routes';

interface UserProps { }
const RenewalPaymentScreen: React.FC<UserProps> = () => {
	const Razorpay = useRazorpay();
	const dispatch = useDispatch();
	const Location = useLocation();
	const navigate = useNavigate();

	const { t } = useTranslation();

	const responseData = useSelector(selectPolicyRenewal);
	const responseLoading = useSelector(selectPolicyRenewalLoading);
	// const policyData = useMemo(
	// 	() => (Location?.state?.data ? Location?.state?.data : {}),
	// 	[Location?.state?.data]
	// );

	// const paymentDetails = useSelector(selectPaymentDetails);
	const userProfile = useSelector(selectUserProfile);

	const dateValue = responseData && responseData?.renewalDueDate;
	const formattedDate = getFormatDate(dateValue);

	useEffect(() => {
		if (!userProfile?.Customer?.eiaNo) {
			dispatch(fetchUserProfile());
		}
	}, [dispatch]);
	useEffect(() => {
		const payload = {
			policyDetail: {
				policyNo: Location?.state?.policyNo
					? Location?.state?.policyNo
					: Location?.state?.data?.policyNo,
				action: 'renewalQuote',
				insuranceCmpnyCd: Location?.state?.companyCode
					? Location?.state?.companyCode
					: Location?.state?.data?.companyCode,
				insuranceCmpnyName: Location?.state?.companyName
					? Location?.state?.companyName
					: Location?.state?.data?.companyName,
				alternateCode: Location?.state?.alternateCode
					? Location?.state?.alternateCode
					: Location?.state?.data?.alternateCode,
				dob: Location?.state?.dob
					? Location?.state?.dob
					: Location?.state?.data?.dob,
			},
			navigate
		};
		// const payload ={
		// 	policyDetail:{
		// 		eiaNo: "5000000000378",
		// 		policyNo: "00000000000358075",
		// 		action: "renewalQuote",
		// 		insuranceCmpnyCd: "117",
		//    insuranceCmpnyName:"SBI General Insurance Co. Ltd.",
		// 		alternateCode: "L"
		// 	}
		// }

		dispatch(policyRenewalRequest(payload as any));
	}, [dispatch]);

	const initiateRazorPay = async (paymentData: any) => {
		debugger;
		console.log(responseData?.companyCode);
		try {
			const options = {
				key: responseData?.companyCode == 10 ? process.env.REACT_APP_RAZOR_PAY_API_KEY : process.env.REACT_APP_RAZOR_PAY_SBIG_API_KEY, // Enter the Key ID generated from the Dashboard
				currency: paymentData.currency,
				amount: Math.ceil(
					(paymentData?.amount > 1 ? paymentData?.amount : 1) * 100
				),
				name: 'BIMA CENTRAL',
				description: paymentData?.description,
				image: paymentData?.image
					? paymentData?.image
					: 'https://i.imgur.com/3g7nmJC.png',
				order_id: responseData?.rzpOrderId,
				handler(paymentResponse: any) {
					const payload = {
						gateWayType: PAYMENT_GATEWAY_TYPES.RAZORPAY,
						paymentResponse: {
							...paymentResponse,
							transactionId: paymentData?.transactionId,
							order_id: responseData?.rzpOrderId,
						},
						status: PAYMENT_STATUS.SUCCESS,
						paymentData,
					};
					navigate(APP_ROUTES.RENEWAL_PAYMENT_SUCCESS, { state: payload });
				},
				prefill: {
					email: paymentData?.prefill?.email,
					contact: `+91${paymentData?.prefill?.contact}`,
					name: paymentData?.prefill?.name,
				},
				notes: {
					address: 'Razorpay Corporate Office',
				},

				theme: { color: '#F37254' },
			};
			const rzp1 = new Razorpay(options as any);
			rzp1.on('payment.failed', (errorResponse: any) => {
				const payload = {
					gateWayType: PAYMENT_GATEWAY_TYPES.RAZORPAY,
					paymentResponse: {
						...errorResponse,
						transactionId: paymentData?.transactionId,
						order_id: responseData?.rzpOrderId,
					},
					status: PAYMENT_STATUS.ERROR,
					paymentData,
				};
				navigate(APP_ROUTES.RENEWAL_PAYMENT_FAILED, {
					state: { ...payload, ...paymentData },
				});
			});

			rzp1.open();
		} catch (error) {
			// eslint-disable-next-line no-console
		}
	};

	const handlePay = () => {
		const paymentData = {
			description: 'Pay policy premium Amount',
			image: null,
			currency: 'INR',
			transactionId: responseData?.transactionId,
			amount: Number(responseData?.premiumAmount)
				? Number(responseData?.premiumAmount)
				: 0,
			prefill: {
				email: userProfile?.Customer?.email,
				contact: userProfile?.Customer?.mobileNo,
				name: userProfile?.Customer?.customerName,
			},
		};

		initiateRazorPay(paymentData);
	};

	return (
		<>
			{responseLoading ? (
				<Grid
					className="vh-95"
					container
					justifyContent="center"
					alignItems="center"

				>
					<Grid item className="text-center">
						<CircularProgress />
					</Grid>
				</Grid>
			) : (
				Location?.state?.data?.alternateCode === 'G' ?
					<>
						<Grid container display="flex" justifyContent="center" >
							<Grid item display="flex" justifyContent="center">
								<Grid item alignItems="center" xs={8} >
									<Grid
										item
										container
										xs={12}
										display="flex"
										justifyContent="space-between"
										className="py-20"

									>
										<Typography
											fontSize={16}
											color={theme.palette.primary.main}
											fontWeight={600}
										>
											{t('RENEWAL_PAYMENT')}
										</Typography>
										<CustomButton
											text={t('CLOSE')}
											endIcon={Cancel}
											showIcon
											color="primary"
											className="f-16 fw-400 button-text"
											variant="text"
											onClick={() => navigate(-1)}
										/>
									</Grid>
									<Grid item xs={12}>
										<Separator color={theme.palette.grey[300]} />
									</Grid>
									<Grid
										item
										container
										xs={12}
										display="flex"
										justifyContent="space-between"
										className="py-20 mt-10"
									>
										<Grid item container xs={8}>
											<Grid item xs={12}>
												<Typography
													fontSize={18}
													color={theme.palette.primary.main}
													fontWeight={600}
												>
													{responseData?.productName}
												</Typography>
											</Grid>
											<Grid item sm={5} xs={5} lg={1} xl={1} md={1}>
												<Typography
													fontSize={14}
													color={theme.palette.common.black}
												>
													{`${t('POLICY')}#`}{" "} {responseData?.previousPolicyNo}
												</Typography>
											</Grid>
											{/* <Grid item xs={8}>
											<Typography
												fontSize={14}
												color={theme.palette.common.black}
												fontWeight={600}
											>
												{responseData?.previousPolicyNo}
											</Typography>
										</Grid> */}
										</Grid>
										<Grid item>
											<img
												src={
													Location?.state?.data?.companyLogo
														? Location?.state?.data?.companyLogo
														: Location?.state?.data?.insurerLogo
												}
												alt="Logo"
												width={60}
												height={60}
												className="	image-border"
											/>
										</Grid>
									</Grid>
									<Grid item xs={12} container className="mt-5">
										<Grid xs={12} display="flex">
											<Grid item xs={12}>
												<Typography
													fontSize={14}
													color={theme.palette.primary.main}
												>
													{t('POLICY_EXPIRED_DATE')}
												</Typography>
												<Typography
													fontSize={16}
													color={theme.palette.common.black}
													fontWeight={600}
												>
													{formattedDate}
												</Typography>
											</Grid>
											<Grid
												item
												xs={12}
												className="py-10 mt-5"
												display="flex"
												justifyContent="flex-end"
											>
												<Grid>
													<Typography
														fontSize={14}
														color={theme.palette.primary.main}
													>
														{t('INSURED_NAME')}
													</Typography>
													<Typography
														fontSize={16}
														color={theme.palette.common.black}
														fontWeight={600}
													>
														{responseData?.customerName}
													</Typography>
												</Grid>
											</Grid>
										</Grid>

										{/* <Grid item xs={12} className="py-10">
							<Typography fontSize={14} color={theme.palette.primary.main}>
								Nominee
							</Typography>
							<Typography
								fontSize={16}
								color={theme.palette.common.black}
								fontWeight={600}
							>
								[Nominee Name]
							</Typography>
						</Grid> */}
										<Grid
											item
											sm={5}
											xs={5}
											lg={1}
											xl={1}
											md={1}
											className="mt-15 mb-5"
										>
											<Typography
												fontSize={14}
												color={theme.palette.common.black}
											>
												{`${t('QUATATION')}#`}
											</Typography>
										</Grid>
										<Grid item xs={9} className="mt-15 mb-5">
											<Typography
												fontSize={14}
												color={theme.palette.common.black}
												fontWeight={600}
											>
												{responseData?.renewalQuoteNumber}
											</Typography>
										</Grid>
										<Grid item xs={12}>
											<Separator color={theme.palette.grey[300]} />
										</Grid>
										{responseData?.coverage > 0 && (
											<Grid item xs={12} className=" mt-20 mb-10">
												<Typography
													fontSize={14}
													color={theme.palette.primary.main}
												>
													{t('COVERAGE')}
												</Typography>
												<LabelWithIcon
													justifyContent="flex-start"
													Icon={RupeeOrangeIcon}
													label={responseData?.coverage}
													labelProps={{
														color: theme.palette.common.black,
														fontSize: 20,
														fontWeight: 600,
													}}
												/>
											</Grid>
										)}

										<Grid item xs={12}>
											<Separator color={theme.palette.grey[300]} />
										</Grid>
										<Grid item xs={12} className=" mt-20 mb-15" container>
											<Grid item xs={12}>
												<Typography
													fontSize={14}
													color={theme.palette.primary.main}
												>
													{t('TOTAL_PREMIUM')}
												</Typography>
											</Grid>
											<Grid item xs={3}>
												<LabelWithIcon
													justifyContent="flex-start"
													Icon={RupeeOrangeIcon}
													label={convertAmount(
														responseData?.renewalPremiumAmount
													)}
													labelProps={{
														color: theme.palette.common.black,
														fontSize: 28,
														fontWeight: 600,
													}}
												/>
											</Grid>
											<Grid item xs={4} className="mt-10">
												<Typography
													fontSize={14}
													color={theme.palette.common.black}
												>
													{t('PER_YEAR')}
												</Typography>
											</Grid>
											<Grid
												item
												container
												xs={12}
												className="widget-title widgettitle mb-15 mt-10"
											>
												<Grid item container xs={12}>
													<Grid item xs={5}>
														<Grid item xs={12}>
															<Typography
																color="#7f7f7f"
																fontSize={14}
																textAlign="justify"
															>
																{t('BASE_PREMIUM')}
															</Typography>
															<LabelWithIcon
																svgClassName="mb-2"
																justifyContent="flex-start"
																Icon={RupeeIconBlack}
																label={convertAmount(
																	responseData?.annualGrossPremium
																)}
																labelProps={{
																	color: '#7f7f7f',
																	fontSize: 18,
																	fontWeight: 600,
																}}
															/>
														</Grid>
													</Grid>
													<Grid item xs={1}>
														<SvgConverter
															Icon={PlusBlack}
															className=" mt-25 mr-20"
														/>
													</Grid>
													<Grid item xs={6} >
														<Typography
															color="#7f7f7f"
															fontSize={14}
															className="text-wrap"
															textAlign="end"
															marginRight={1}
														>
															{`${t('GST')}(18%)`}
														</Typography>
														<LabelWithIcon

															svgClassName="mb-2"
															justifyContent="end"
															Icon={RupeeIconBlack}
															label={convertAmount(responseData?.gst)}
															labelProps={{
																color: '#7f7f7f',
																fontSize: 18,
																fontWeight: 600,
															}}
														/>
													</Grid>
												</Grid>
											</Grid>
										</Grid>
										<Grid item xs={12}>
											<Separator color={theme.palette.grey[300]} />
										</Grid>
										<Grid
											item
											container
											xs={12}
											display="flex"
											justifyContent="space-between"
											className="py-20"
											alignItems="center"
										>
											<Link to={APP_ROUTES.DASHBOARD}>
												<Typography
													fontSize={16}
													color={theme.palette.primary.main}
												>
													{t('CANCEL')}
												</Typography>
											</Link>

											<CustomButton
												text={t('PAY')}
												color="primary"
												onClick={handlePay}
												className="f-16 fw-600"
												variant="text"
											/>
										</Grid>
										<Grid item xs={12}>
											<Separator color={theme.palette.grey[300]} />
										</Grid>
										{/* <Grid item xs={12} className="py-10 mt-10">
										<Typography
											fontSize={12}
											color={theme.palette.primary.main}
										>
											{t('TERMS_&_CONDITIONS')}
										</Typography>
									</Grid> */}
									</Grid>
								</Grid>
							</Grid>
						</Grid>
					</> : <Grid container justifyContent="center" mt={5}>
						<Grid item className="cal-payment-screen">
							<Grid container justifyContent="space-between" alignItems="center">
								<Grid item>
									<Typography className="f-16 fw-600 mt-n10" color="primary">
										{t('PREMIUM_PAYMENT')}
									</Typography>
								</Grid>
								<Grid item>
									<CustomButton
										text={t('CLOSE')}
										variant="text"
										color="primary"
										showIcon
										fontSize={14}
										onClick={() => navigate(-1)}
										endIcon={Cancel}
									/>
								</Grid>
							</Grid>
							<Grid item className="my-10">
								<Separator color={theme.palette.grey[300]} />
							</Grid>
							<Grid
								container
								justifyContent="space-between"
								className="mb-15"
								wrap="nowrap"
							>
								<Grid xs={12} item>
									<Grid display="flex" justifyContent="space-between">
										<Grid item>
											<Typography
												className="f-18 fw-600"
												color={theme.palette.primary.main}
											>
												{responseData?.insurerCompanyName}
											</Typography>

											<Typography className="f-14" color={theme.palette.common.black}>
												{`${t('POLICY_FOT_RENEWAL')}#`}{" "} {responseData?.previousPolicyNo}
											</Typography>
										</Grid>

										<Grid item className="mt-10">
											<img
												src={
													Location.state.data.companyLogo
														? Location.state.data?.companyLogo
														: Location.state.data?.insurerLogo
												}
												alt="logo"
												width="60"
												height="60"
												style={{ objectFit: 'contain' }}
											/>
										</Grid>
									</Grid>
									<Grid xs={12} display="flex" justifyContent="space-between">
										<Grid item className="mt-10">
											<Typography className="f-14" color={theme.palette.primary.main}>
												{t('LIFE_ASSURED')}
											</Typography>
											<Typography
												className="f-16 fw-600"
												color={theme.palette.common.black}
											>
												{responseData?.customerName}
											</Typography>
										</Grid>
										<Grid item className="mt-10">
											<Typography className="f-14" color={theme.palette.primary.main}>
												{t('SUM_ASSURED')}
											</Typography>
											<Grid container alignItems="center" spacing={0.5}>
												<Grid item>
													<SvgConverter Icon={RupeeOrangeIcon} />
												</Grid>
												<Grid item>
													<Typography
														className="f-20 fw-600"
														color={theme.palette.common.black}
													>
														{convertAmount(Location.state.data.sumAssured)}
													</Typography>
												</Grid>
											</Grid>
										</Grid>
									</Grid>
								</Grid>
							</Grid>
							<Separator color={theme.palette.grey[300]} />
							<Grid item className="mt-10 ">
								<Typography className="f-14" color={theme.palette.primary.main}>
									{t('RENEWAL_PREMIUM')}
								</Typography>

								<Grid container spacing={0.5} alignItems="center">
									<Grid item>
										<SvgConverter Icon={RupeeOrangeIcon} />
									</Grid>
									<Grid item>
										<Typography
											className="f-60 fw-600"
											color={theme.palette.common.black}
										>
											{convertAmount(responseData?.premiumAmount)}
										</Typography>
									</Grid>
								</Grid>


								<Grid item className="mt-10">
									<Separator color={theme.palette.grey[300]} />
								</Grid>

								{/* <Grid container className="payment-options mt-8">
            <Grid item className="mb-10">
              <Typography className="f-12" color={theme.palette.primary.main}>
                Select a Payment Gateway
              </Typography>
            </Grid>
            <FormControl>
              <RadioGroup value={selectedValue} onChange={handleChange}>
                <FormControlLabel
                  value="camspay"
                  control={<Radio size="small" />}
                  label={
                    <img src={Camspay} alt="logo" width={"123"} height={"25"} />
                  }
                />
                <FormControlLabel
                  value="razorpay"
                  control={<Radio size="small" />}
                  label={
                    <img
                      src={RazorpayIcon}
                      alt="logo"
                      width={"143"}
                      height={"31"}
                    />
                  }
                />
              </RadioGroup>
            </FormControl>
          </Grid>
          <Grid item className="mt-10">
            <Separator color={theme.palette.grey[300]} />
          </Grid> */}
								<Grid container justifyContent="space-between" className="mt-10">
									<Grid item>
										<Link to={APP_ROUTES.DASHBOARD}>
											<Typography
												fontSize={16}
												color={theme.palette.primary.main}
											>
												{t('CANCEL')}
											</Typography>
										</Link>
									</Grid>
									<Grid item>
										<CustomButton
											text={t('PAY')}
											variant="text"
											color="primary"
											className='f-16 fw-600'
											// showIcon={false}
											fontSize={14}
											onClick={handlePay}
										// endIcon={rightArrow}

										/>
									</Grid>
								</Grid>
							</Grid>
							<Grid item className="mt-10">
								<Separator color={theme.palette.grey[300]} />
							</Grid>
							{Location?.state?.companyCode
								? Location?.state?.companyCode === '10'
								: Location?.state?.data?.companyCode === '10' && <Grid item className="mt-15">
									<Typography className="f-12" color={theme.palette.primary.main}>
										{t('Disclaimer')}
									</Typography>
									<Typography className="f-12" color={theme.palette.grey.A700}>
										{t('Payment gateway charges will be applicable on credit card transactions')}.
									</Typography>
								</Grid>}
						</Grid>
					</Grid>
			)}
		</>
	);
};

export default RenewalPaymentScreen;
