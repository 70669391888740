import { Grid } from '@mui/material';
import React from 'react';
import '../../../Policy.scss';
import Typography from '@mui/material/Typography';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import theme from '../../../../../../common/theme';
import CustomButton from '../../../../../../common/ui/CustomButton';
import CommunicationDetails from '../common/CommunicationDetails';
import { APP_ROUTES } from '../../../../../../common/constants/Routes';
import { POLICY_LOCK_FLAG } from '../../../../../../common/Enums';

interface CommunicationProps {
	lifeDetails?: any;
	isArchive?: any;
}
const Communication: React.FC<CommunicationProps> = ({ lifeDetails, isArchive }) => {
	const { t } = useTranslation();
	return (
		<Grid container className="py-10">
			<CommunicationDetails communicationDetails={lifeDetails} isArchive={isArchive} />
			<Grid item container xs={11}>
				<Grid item xs={12}>
					<Typography
						color={theme.palette.primary.main}
						fontSize={14}
						fontWeight={600}
					>
						{t('BANK')}[{t('MATURITY_BENEFIT')}]
					</Typography>
				</Grid>
				<Grid item xs={8}>
					{lifeDetails && lifeDetails?.bankName !== '' ? (
						<Typography
							color={theme.palette.primary.main}
							fontSize={18}
							fontWeight={600}
							className="mb-5"
						>
							{lifeDetails?.bankName}
						</Typography>
					) : (
						'-'
					)}

					{lifeDetails && lifeDetails?.accountHolderName !== '' ? (
						<Typography color={theme.palette.common.black} fontSize={16}>
							{lifeDetails?.accountHolderName}
						</Typography>
					) : null}
					{lifeDetails && lifeDetails?.bankAcNo !== '' ? (
						<Typography color={theme.palette.common.black} fontSize={16}>
							{lifeDetails?.bankAcNo}
						</Typography>
					) : null}
					{lifeDetails && lifeDetails?.bankName !== '' ? (
						<Typography color={theme.palette.common.black} fontSize={16}>
							{lifeDetails?.bankName}
						</Typography>
					) : null}
					{lifeDetails && lifeDetails?.bankBranch !== '' ? (
						<Typography color={theme.palette.common.black} fontSize={16}>
							{lifeDetails?.bankBranch}
						</Typography>
					) : null}
					{lifeDetails && lifeDetails?.bankIfscCode !== '' ? (
						<Typography color={theme.palette.common.black} fontSize={16}>
							{lifeDetails?.bankIfscCode}
						</Typography>
					) : null}
					{/* {!isArchive && lifeDetails?.amcIntegFlag === 'Y' && <Link to={APP_ROUTES.BANK}>
						<CustomButton
							text={t('CHANGE_BANK')}
							variant="text"
							color="primary"
							showIcon={false}
							fontSize={12}
						/>
					</Link>} */}

					{!isArchive &&
						lifeDetails?.amcIntegFlag === 'Y' &&
						lifeDetails?.bankEndoFlag === 'Y' &&
						!POLICY_LOCK_FLAG.includes(lifeDetails?.daFlag) &&
						lifeDetails &&
						lifeDetails?.accountHolderName !== '' &&
						lifeDetails &&
						lifeDetails?.bankAcNo !== '' &&
						lifeDetails &&
						lifeDetails?.bankName !== '' &&
						lifeDetails &&
						lifeDetails?.bankBranch !== '' &&
						lifeDetails &&
						lifeDetails?.bankIfscCode !== '' ? (
						<Link to={APP_ROUTES.MANAGE_ADDRESS}>
							<CustomButton
								text={t('CHANGE_BANK')}
								variant="text"
								color="primary"
								showIcon={false}
								fontSize={12}
							/>
						</Link>
					) : (
						lifeDetails?.amcIntegFlag === 'Y' &&
						lifeDetails &&
						lifeDetails?.accountHolderName === '' &&
						lifeDetails &&
						lifeDetails?.bankAcNo === '' &&
						lifeDetails &&
						lifeDetails?.bankName === '' &&
						lifeDetails &&
						lifeDetails?.bankBranch === '' &&
						lifeDetails &&
						lifeDetails?.bankIfscCode === '' && (
							<Link to={APP_ROUTES.MANAGE_ADDRESS}>
								<CustomButton
									text={t('TAP_TO_ADD')}
									variant="text"
									color="primary"
									showIcon={false}
									fontSize={12}
								/>
							</Link>
						)
					)}
				</Grid>
				<Grid item xs={4}>
					<Grid item container xs={12} display="flex" justifyContent="flex-end">
						{lifeDetails?.bankLogo && (
							<img
								src={lifeDetails?.bankLogo}
								width={73}
								height={77}
								alt="bank"
							/>
						)}
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
};
export default Communication;
