import React from 'react';
import { Grid } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import CustomButton from '../../../common/ui/CustomButton';
import NomineeCardHeader from './NomineeComponents/NomineeCardHeader';
import Separator from '../../../common/ui/Separator';
import NomineeCardList from './NomineeComponents/NomineeCardList';
import theme from '../../../common/theme';
import { APP_ROUTES } from '../../../common/constants/Routes';
import { ReactComponent as Edit } from '../../../common/icons/edit.svg';
import { POLICY_LOCK_FLAG } from '../../../common/Enums';

interface Props {
	productName: string;
	selfName: string;
	sumAssured: string;
	premium: string;
	companyLogo: string;
	nominees: any;
	cardData: any;
	overallPercentage: any;
	isVerified?: any;
	nomineeCount: any;
	nomineeEndoFlag?: any;
}
const NomineeCard = ({
	productName,
	selfName,
	sumAssured,
	premium,
	companyLogo,
	nominees,
	cardData,
	overallPercentage,
	isVerified,
	nomineeCount,
	nomineeEndoFlag,
}: Props) => {
	const navigate = useNavigate();

	const handleEditNominee = () => {
		navigate(APP_ROUTES.EDITNOMINEE, { state: { cardData } });
	};

	const { t } = useTranslation();

	return (
		<Grid item>
			<Grid item>
				<Grid container className="nominee-box">
					<Grid item xs={12}>
						<Grid item ml={2}>
							<NomineeCardHeader
								productName={productName}
								selfName={selfName}
								sumAssured={sumAssured}
								premium={premium}
								bankImage={companyLogo}
								cardData={cardData}
							/>
						</Grid>

						<Separator color={theme.palette.primary.main} />
					</Grid>

					<Grid item>
						<NomineeCardList
							nominees={nominees}
							overallPercentage={overallPercentage}
							isVerified={isVerified}
							nomineeCount={nomineeCount}
						/>
					</Grid>
					<Separator color={theme.palette.primary.main} borderWidth={1} />
					<Grid item xs={12} className="ml-20 py-10 ">
						<CustomButton
							className="f-16 fw-600"
							text={t('EDIT_NOMINEES')}
							variant="text"
							color="primary"
							showIcon={false}
							startIcon={Edit}
							disabled={
								(POLICY_LOCK_FLAG.includes(cardData?.daFlag) || cardData?.insIntegFlag?.toUpperCase() !== 'Y') || cardData?.nomineeEndoFlag?.toUpperCase() !== 'Y'
							}

							onClick={handleEditNominee}
						/>
					</Grid>
				</Grid>
			</Grid>
		</Grid>
	);
};

export default NomineeCard;
